.Timeline {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 6rem;
}

.Timeline::after {
    background-color: #ffa500;
    content: '';
    position: absolute;
    left: calc(50% - 2px);
    width: 4px;
    height: 100%;
}

@media (max-width: 900px) {
    .Timeline::after {
        left: 10%;
    }
}

@media (max-width: 600px) {
    .Timeline::after {
        left: 15%;
    }
}