.TimelineItem {
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-top: 2.5rem;
    width: 50%;
}

@media (max-width: 900px) {
    .TimelineItem {
        width: 95%;
        left: 10%;
    }
}

@media (max-width: 600px) {
    .TimelineItem {
        width: 85%;
        left: 15%;
    }
}

.TimelineItem:nth-child(odd) {
    align-self: flex-end;
    justify-content: flex-start;
}

@media (max-width: 900px) {
    .TimelineItem:nth-child(odd) {
        align-self: auto;
    }
}

.TimelineItemContent {
    border-radius: 5px;
    background-color: white;
    color: black;
    padding: 0 1rem;
    margin-right: 5rem;
    position: relative;
    max-width: 70%;
    margin-top: 1rem;
}

@media (max-width: 600px) {
    .TimelineItemContent {
        max-width: 50%;
    }
}

.TimelineItem:nth-child(odd) .TimelineItemContent {
    margin-left: 5rem;
    margin-right: 0;
}

@media (max-width: 900px) {
    .TimelineItemContent {
        margin-left: 5rem;
        margin-right: auto;
    }
}

.TimelineItemContent .Arrow {
    content: "";
    position: absolute;
    top: 25px;
    left: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-left-color: #ffa500;
}

@media (max-width: 900px) {
    .TimelineItemContent .Arrow {
        left: auto;
        right: 100%;
        border-left-color: transparent;
        border-right-color: #ffa500;
    }
}

.TimelineItem:nth-child(odd) .TimelineItemContent .Arrow {
    left: auto;
    right: 100%;
    border-left-color: transparent;
    border-right-color: #ffa500;
}

.TimelineItem .TimelineItemCenter {
    position: absolute;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    right: -4rem;
    width: 8rem;
    border-radius: 50%;
    padding-top: 1rem;
}

@media (max-width: 900px) {
    .TimelineItem .TimelineItemCenter {
        right: auto;
        left: -4rem;
    }
}

.TimelineItem:nth-child(odd) .TimelineItemCenter {
    right: auto;
    left: -4rem;
}

.TimelineItem .TimelineItemCenter .Logo {
    display: flex;
    justify-content: center;
    background-color: white;
    padding: 0.5rem;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
}