.ContactItems {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 5rem;
}

@media (max-width: 900px) {
    .ContactItems {
        flex-direction: column;
        gap: 2rem;
    }
}