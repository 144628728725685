.Content {
    margin: 4rem 4rem 0 4rem;
    padding: 4rem 0 2rem 0;
}

@media (max-width: 600px) {
    .Content {
        margin: 4rem 1rem 0 1rem;
        padding: 2rem 0 2rem 0;
    }
}