.MenuToggle {
    width: 60px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    transform: rotate(0deg);
}

.MenuToggle span {
    background-color: #fff;
    width: 60%;
    height: 2.5px;
    border-radius: 5px;
    transition-timing-function: ease;
    transition-duration: 1000ms;
    transform-origin: center;
}

.MenuToggle span.active:nth-child(1) {
    transform: translate3d(0, 12px, 0) rotate(405deg);
    width: 70%;
    height: 3px;
}

.MenuToggle span.active:nth-child(2) {
    transition-timing-function: ease-out;
    transition-duration: 100ms;
    opacity: 0;
}

.MenuToggle span.active:nth-child(3) {
    transform: translate3d(0, -13px, 0) rotate(-405deg);
    width: 70%;
    height: 3px;
}