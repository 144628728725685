.Card {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.CardImage {
    height: 4rem;
    width: auto;
}

.White {
    background-color: white;
    padding: 0.25rem;
    border-radius: 5%;
}