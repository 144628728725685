.NavigationBar {
    position: fixed;
    top: 0;
    left: 4rem;
    right: 4rem;
    height: 4rem;
    background-color: black;
    z-index: 200;
}

.NavigationBarDesktop {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.NavigationBarMobile {
    display: none;
}

.NavigationBarItems {
    min-width: 250px;
    display: flex;
    justify-content: space-between;
}

@media (max-width: 600px) {
    .NavigationBar {
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
    }

    .NavigationBarDesktop {
        display: none;
    }

    .NavigationBarMobile {
        display: block;
        height: 60%;
    }

    .NavigationMenu {
        position: fixed;
        width: 100%;
        z-index: 50;
        background-color: black;
        padding-bottom: 1rem;
    }

    .NavigationMenuItems {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}